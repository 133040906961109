import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PRODUCT_FAMILY, PRODUCT_TYPES } from '../../../core/helpers/constants';
import { atleasOneTrue, createEmptyGuid, ordinalSuffixOf } from '../../../core/helpers/utility';
import { ICompany } from '../../../core/models/datafeed.models';
import { IProductFamily, IProductMain, IProductCompanies } from '../../../core/models/products.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';
import { ProductsApiService } from '../../../pages/products/products-api.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-products-modal',
  templateUrl: './products-modal.component.html',
  styleUrls: ['./products-modal.component.scss']
})
export class ProductsModalComponent implements OnInit, OnDestroy {

  PRODUCT_FAMILY = PRODUCT_FAMILY;
  PRODUCT_TYPES = PRODUCT_TYPES;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  @Input() set iId(value: string) {
    if (value !== null) {
      this.id = value;
      this.loadDetails();
    }
  }
  allProducts = new Array<IProductMain>();
  allProductsFiltered: Array<Array<IProductMain>> = new Array<Array<IProductMain>>();
  allCompaniesFeed = new Array<ICompany>();




  @Output() oEditComplete = new EventEmitter<any>();
  @Output() oSaveClicked = new EventEmitter<any>();


  existingTypeValue: number = 1;
  companyOptions = [] as Array<ICompany>;

  frequencyOptions: Array<any> = [];
  durationOptions = [
    { title: 'day(s)', value: 'day' },
    { title: 'week(s)', value: 'week' },
    { title: 'month(s)', value: 'month' },
    { title: 'year(s)', value: 'year' }];
  expiresAfterOptions: Array<string> = new Array<string>();
  synchronizeRenewalsOptions: Array<any> = new Array<any>();



  bundleChoice?= true;
  productsForm: FormGroup;
  submitted = false;
  id: string = '';
  allProductFamilies: Array<IProductFamily> = new Array<IProductFamily>();

  constructor(
    public activeModal: NgbActiveModal,
    private appService: AppService,
    private datafeedService: DataFeedService,
    private productApiService: ProductsApiService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.productsForm = this.formBuilder.group({
      id: [null],
      type: [1],
      name: [null, [Validators.required]],
      display: [null, [Validators.required]],
      description: null,
      isBundleOnly: false,
      isDefault: false,
      familyCode: [1, Validators.required],
      isGoogle: [null],
      isFacebook: [null],
      isTikTok: [null],
      canCustomerChoose: [null],
      selectedCompanyIds: [[]],
      data: this.formBuilder.group({
        id: null,
        productId: null,
        price: null,
        type: null,
        frequency: null,
        duration: null,
        expiresAfterCycles: '1',
        signupFee: null,
        synchronizeRenewls: null,
      }),
      media: this.formBuilder.group({
        id: null,
        productId: null,
        fileName: [null, Validators.required],
        file: null,
        blob: null,
      }),
      variableSubscription: this.formBuilder.array([]),
      bundleProducts: this.formBuilder.array([])
    });

    this.expiresAfterOptions.push('Until Canceled');
    for (let i = 1; i < 31; i++)
      this.expiresAfterOptions.push(i.toString());

    this.synchronizeRenewalsOptions.push({ title: "No synchronization" });
    this.getAllProductFamilies();
    this.getAllCompaniesFeed();
    this.appService.fetchAllProductsFeed();
  }

  updateRenewelOptions(dur: string) {
    this.synchronizeRenewalsOptions = [];
    for (let i = 1; i < (dur === "week" ? 8 : dur == "month" ? 29 : 0); i++)
      this.synchronizeRenewalsOptions.push({ title: `${ordinalSuffixOf(i)} day of ${dur}`, value: i });
    this.synchronizeRenewalsOptions.push({ title: "No synchronization" });
    this.fProductData["synchronizeRenewls"].setValue(null);
  }
  updateFrequencyOptions() {
    this.frequencyOptions = [];
    for (let i = 1; i < 51; i++)
      this.frequencyOptions.push({ title: i, value: i });
  }

  ngOnInit(): void {
    this.updateFrequencyOptions();

    this.fProductData.duration.valueChanges
      .subscribe((data: any) => {
        this.updateRenewelOptions(data);
      });
  }
  getAllProductFamilies() {
    this.datafeedService.getAllProductFamilies()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        this.allProductFamilies = [...r.data];
      })
  }
  getAllCompaniesFeed() {
    this.datafeedService.getAllCompanies()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((r: any) => {
        this.allCompaniesFeed = [...r.data];
        if (!this.id) {
          var masterCompanyId = this.allCompaniesFeed.find(x => x.name.toLowerCase().indexOf('reach') > -1)?.id;
          this.f.selectedCompanyIds.setValue([masterCompanyId]);
        }
      });
  }

  onFamilyChange(evt: any) {
    if (evt.code === PRODUCT_FAMILY.DigitalMarketing || evt.code === PRODUCT_FAMILY.AdSpend || evt.code === PRODUCT_FAMILY.AddPlatform) {
      this.productsForm.setValidators([atleasOneTrue]);
    }
    else
      this.productsForm.setValidators([]);

    if (evt.code === PRODUCT_FAMILY.Bundle) {
      this.existingTypeValue = this.f.type.value;
      this.f.type.setValue(-1);
    }
    else
      this.f.type.setValue(this.existingTypeValue);
  }
  onCustomerChoosePlatformChange() {
    if (this.productsForm.value.canCustomerChoose) {
      this.productsForm.get('isGoogle')?.setValue(true);
      this.productsForm.get('isFacebook')?.setValue(true);
      this.productsForm.get('isTikTok')?.setValue(true);
    }
  }

  onPlatformChange() {
    if (this.productsForm.value.familyCode !== PRODUCT_FAMILY.AddPlatform &&
      (this.productsForm.value.isGoogle === false || this.productsForm.value.isFacebook === false || this.productsForm.value.isTikTok === false)) {
      this.productsForm.get('canCustomerChoose')?.setValue(false);
    }
  }

  onTypeChange(evt: any) {
    if (evt !== PRODUCT_TYPES.VariableSubscription.code)
      this.fProductData.price.setValidators([Validators.required]);
    else
      this.fProductData.price.setValidators([]);

    if (evt === PRODUCT_TYPES.SimpleSubscription.code || evt === PRODUCT_TYPES.VariableSubscription.code) {
      this.fProductData.frequency.setValidators([Validators.required]);
      this.fProductData.duration.setValidators([Validators.required]);
    }
    else {
      this.fProductData.frequency.setValidators([]);
      this.fProductData.duration.setValidators([]);
    }


  }



  get f() { return this.productsForm.controls; }
  get fProductData() { return (this.productsForm.get('data') as FormGroup).controls; }
  get fProductMedia() { return (this.productsForm.get('media') as FormGroup).controls; }
  get fProductVariableSubscription() { return this.productsForm.get('variableSubscription') as FormArray; }
  get fProductBundle() { return this.productsForm.get('bundleProducts') as FormArray }

  loadDetails() {
    if (this.id) {
      this.productApiService.getProductDetails(this.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(response => {
          if (response.good) {
            if (response.data.data.expiresAfterCycles === -1)
              response.data.data.expiresAfterCycles = 'Until Canceled';


            response.data.media.file = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + response.data.media.blob);
            this.productsForm.patchValue(response.data);
            // response.data.productCompanies.forEach((pc: IProductCompanies) => {
            //   console.log('existing company');
            //   this.f.selectedCompanyIds.setValue([...this.f.selectedCompanyIds.value, pc.companyId]);
            // });
            // console.log(this.f.selectedCompanyIds.value);
            if (this.f.selectedCompanyIds.value.length == 0) {
              this.f.selectedCompanyIds.setValue([this.allCompaniesFeed.find(x => x.name.toLowerCase().indexOf('reach') > -1)?.id]);
            }
            if (response.data.type === PRODUCT_TYPES.VariableSubscription.code)
              for (var vs of response.data.variableSubscription)
                this.addVariableSubscription(vs.id, vs.productId, vs.amount, vs.sortOrder);
            if (response.data.familyCode === PRODUCT_FAMILY.Bundle)
              for (var vs of response.data.bundleProducts)
                this.addBundleProduct(vs.id, vs.assignedProductId, vs.productId, vs.isRequired, vs.sortOrder);

            if (response.data.familyCode === PRODUCT_FAMILY.DigitalMarketing || response.data.familyCode === PRODUCT_FAMILY.AdSpend) {
              this.productsForm.setValidators([atleasOneTrue]);
            }
            else
              this.productsForm.setValidators([]);

            

            this.onTypeChange(response.data.type);
          }
        });
    } 
  }

  updateData(data: IProductMain) {
    this.productsForm.patchValue(data);
  }


  
  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  onSubmit() {
    this.submitted = true;
    if (this.productsForm.invalid)
      return;

    let frmValues = this.productsForm.value;

    this.trimObjectStrings(frmValues)

    if (frmValues.data.expiresAfterCycles) {
      if (frmValues.data.expiresAfterCycles === 'Until Canceled')
        frmValues.data.expiresAfterCycles = -1;
      else
        frmValues.data.expiresAfterCycles = parseInt(frmValues.data.expiresAfterCycles);
    }


    this.oSaveClicked.emit(this.productsForm.value);
  }

  resetBundleProduct(){
    this.fProductBundle.clear();
    this.allProductsFiltered = new Array<Array<IProductMain>>();
  }

  onRemoveCompany(event: any){
    console.log('on remove');
    if (this.f.selectedCompanyIds.value.length == 0){
      this.f.selectedCompanyIds.setValue([event.value.id]);
    }
  }

  addBundleProduct(id?: string, assignedProductId?: string, productId?: string, required?: boolean, sortOrder?: number) {
    if (sortOrder !== null && sortOrder !== undefined)
      sortOrder = this.fProductBundle.length + 1
    let selectedProducts = _.flatten(this.fProductBundle.controls.map(x => x.value.assignedProductId));
    const sub = this.appService.getAllProductsFeed$()
      .pipe(first(val => val.length > 0))
      .subscribe((prods: Array<IProductMain>) => {
        
        if(this.productsForm.value.isDefault == true){
          this.allProductsFiltered.push(prods.filter(x => !selectedProducts.includes(x.id) && x.familyCode != PRODUCT_FAMILY.Bundle && x.isDefault == true));
        }
        else if(this.f.selectedCompanyIds.value.length > 0){
          this.allProductsFiltered.push(prods.filter(x => !selectedProducts.includes(x.id) && x.familyCode != PRODUCT_FAMILY.Bundle && (x.isDefault == true || x.productCompanies.filter(y => this.f.selectedCompanyIds.value.includes(y.companyId)).length > 0)));
        }
        else{
          this.allProductsFiltered.push(prods.filter(x => !selectedProducts.includes(x.id) && x.familyCode != PRODUCT_FAMILY.Bundle));
        }
        
        this.fProductBundle.push(this.newBundleProduct(id, assignedProductId, productId, required, sortOrder ?? 1));
        setTimeout(() => { sub.unsubscribe(); }, 1);
      })


  }
  newBundleProduct(id?: string, assignedProductId?: string, productId?: string, required?: boolean, sortOrder?: number) {
    return this.formBuilder.group({
      id: id || createEmptyGuid(),
      assignedProductId: assignedProductId,
      productId: productId,
      isRequired: required || false,
      sortORder: sortOrder
    });
  }
  deleteBundleProduct(i: number) {
    this.fProductBundle.removeAt(i);
    this.allProductsFiltered.splice(i, 1);
  }




  addVariableSubscription(id?: string, productId?: string, amount?: number, sortOrder?: number) {
    this.fProductVariableSubscription.push(this.newVariableSubscriptions(id, productId, amount, sortOrder));
  }
  newVariableSubscriptions(id?: string, productId?: string, amount?: number, sortOrder?: number): FormGroup {
    return this.formBuilder.group({
      id: id || createEmptyGuid(),
      productId: productId,
      amount: [amount || null, Validators.required],
      sortOrder: sortOrder || 1,
    })
  }
  deleteVariableSubscription(i: number) {
    this.fProductVariableSubscription.removeAt(i);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


  makeImageById(id: string) {
    this.productApiService.getProductMedia(id)
      .subscribe((x: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(x); //FileStream response from .NET core backend
        reader.onload = _event => {
          this.fProductMedia.file.setValue(reader.result);
        };
      });
  }

  getFile(e: any) {
    let file = e.target.files[0];

    if (file.size / 1024 / 1024 > 20) {
      alert("File size should be less than 20MB")
      return;
    }
    let extensionAllowed = ["png", "svg", "jpg", "jpeg", "ico"];
    if (extensionAllowed) {
      var nam = file.name.split('.').pop();
      if (!extensionAllowed.find(f => f == nam)) {
        alert("Please upload " + Object.keys(extensionAllowed) + " file.")
        return;
      }
    }
    this.fProductMedia.fileName.setValue(file.name);
    //
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      this.fProductMedia.file.setValue(reader.result);
    }

    //




    let fData = new FormData();
    fData.append("Files", file, file.name);
    let mediaId = this.fProductMedia.id.value;
    if (mediaId)
      fData.append("mediaId", this.fProductMedia.id.value);
    if (this.f.id.value)
      fData.append("productId", this.f.id.value);
    const sub = this.productApiService.saveProductMedia(fData)
      .subscribe((res: any) => {
        if (res.good) {
          this.fProductMedia.id.setValue(res.data)
          sub.unsubscribe();
        }
      });

  }
  // createImageFromBlob(image: Blob) {
  //   let reader = new FileReader();
  //   reader.addEventListener("load",
  //     () => {
  //         this.imageToShow.photo = reader.result;
  //     },
  //     false);

  //   if (image) {
  //     if (image.type !== "application/pdf")
  //       reader.readAsDataURL(image);
  //   }
  // }
  getImageFromData(data: any) {
    const reader = new FileReader();
    reader.readAsDataURL(data); //FileStream response from .NET core backend
    reader.onload = _event => {
      this.fProductMedia.file.setValue(reader.result); //url declared earlier
    };
  }

  rmProductsVariableSubscriptionsDragNDrop(event: any) {
    this.productsForm.markAsDirty();

    let formArray = this.fProductVariableSubscription;
    let subs = formArray.value;

    let evt = event as CdkDragDrop<string[]>;
    let from = evt.previousIndex;
    let to = evt.currentIndex;

    subs[from].sortOrder = subs[to].sortOrder;
    if (from < to) {
      for (var i = from + 1; i <= to; i++) {
        subs[i].sortOrder -= 1;
      }
      for (var i = from + 1; i <= to; i++) {
        this.swapFormArrayRows(formArray, i - 1, i);
      }
    }
    else if (from > to) {
      for (var i = from - 1; i >= to; i--) {
        subs[i].sortOrder += 1;
      }
      for (var i = from - 1; i >= to; i--) {
        this.swapFormArrayRows(formArray, i + 1, i);
      }
    }

    // moveItemInArray(subs, evt.previousIndex, evt.currentIndex);
  }


  swapFormArrayRows(formArray: FormArray, index1: number, index2: number) {
    const extras = [...formArray.value];
    if (index1 >= 0 && index2 >= 0 && index1 != index2) {
      [extras[index1], extras[index2]] = [extras[index2], extras[index1]];
      formArray.setValue(extras);
    }
  }

}